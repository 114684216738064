
export const LOGIN_REQUEST = 'auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'auth/LOGIN_FAILURE';
export const LOGOUT = 'auth/LOGOUT';
export const REFRESH_TOKEN_REQUEST = 'auth/REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'auth/REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'auth/REFRESH_TOKEN_FAILURE';
export const GET_USER_INFO_REQUEST = 'auth/GET_USER_INFO_REQUEST';
export const GET_USER_INFO_SUCCESS = 'auth/GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILURE = 'auth/GET_USER_INFO_FAILURE';
export const SIGNUP_REQUEST = 'auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'auth/SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'auth/SIGNUP_FAILURE';
export const SET_LOADING = 'auth/SET_LOADING';
export const SUCCESS_CHANGED_ROLE_REQUEST = 'auth/SUCCESS_CHANGED_ROLE_REQUEST';
export const ROLE_CHANGED = 'auth/ROLE_CHANGED';
export const ROLE_CHANGED_FAILED = 'auth/ROLE_CHANGED_FAILED';